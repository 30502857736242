import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { HttpProgressBarService } from 'src/app/shared/services/httpprogress.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public isSentEmail: boolean;
  public sendEmailSuccess: string;
  public forgotPasswordForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [
      Validators.required,
      Validators.email
    ])
  });

  get email() {
    return this.forgotPasswordForm.get('email');
  }

  constructor(private authService: AuthService, public httpProgressBarService: HttpProgressBarService) {}

  ngOnInit(): void {
    this.isSentEmail = false;
  }

  sendEmail() {
    this.authService
      .sendEmailForgotPassword({
          email: this.email.value,
        }
      )
      .pipe(first())
      .subscribe(() => {
        this.isSentEmail = true;
        this.sendEmailSuccess = 'Please check your email for password reset link.';
      }, () => {
        this.isSentEmail = true;
        this.sendEmailSuccess = 'Please check your email for password reset link.';
      });
  }

}
