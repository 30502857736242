import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { HttpProgressBarService } from '../../shared/services/httpprogress.service';
import { Observable, Subject, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
    providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
    private throttleLogout = new Subject();
    constructor(private httpProgressBarService: HttpProgressBarService, private snackBar: MatSnackBar) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        setTimeout(() => this.httpProgressBarService.isLoading.next(true), 10);
        return next.handle(req).pipe(
            finalize(
                () => {
                    setTimeout(() => this.httpProgressBarService.isLoading.next(false), 10);
                }
            ),
            catchError((response: HttpErrorResponse) => {
                switch (response.status) {
                    case 401:
                        this.throttleLogout.next(null);
                        break;
                    case 400:
                        this.snackBar.open(`${response.status.toString(10)} ${response.error?.message}`, 'Dismiss', { duration: 3000 });
                        break;
                    default:
                        return throwError(response);
                }
            })
        );
    }

}
