import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../../core/services/user.service';
import { HttpProgressBarService } from 'src/app/shared/services/httpprogress.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignUpComponent implements OnInit {
  public apiError: string;
  public signUpForm: UntypedFormGroup;
  public passwordsMatch = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    userService: UserService,
    public httpProgressBarService: HttpProgressBarService
  ) {
    if (userService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.signUpForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required),
      confirmPassword: new UntypedFormControl('', Validators.required)
    });
  }

  get name() {
    return this.signUpForm.get('name');
  }

  get password() {
    return this.signUpForm.get('password');
  }

  get confirmPassword() {
    return this.signUpForm.get('confirmPassword');
  }

  public comparePassword(event) {
    const newPassword = this.password.value;

    this.passwordsMatch = newPassword && newPassword === event.target.value ? true : false;
  }

  submit() {
    const data = {
      name: this.name.value,
      password: this.password.value,
      token: this.route.snapshot.params.token
    };

    this.authService
      .registerInvitedUser(data)
      .pipe(first())
      .subscribe(
        () => {
          this.router.navigate(['/login']);
        },
        (error) => {
          this.apiError = error.error.message;
        }
      );
  }
}
