import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpProgressBarService } from 'src/app/shared/services/httpprogress.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  public resetPasswordSuccess: string;
  public resetPasswordError: string;
  public resetPasswordForm = new UntypedFormGroup({
    password: new UntypedFormControl('', [
      Validators.required
    ])
  });

  get password() {
    return this.resetPasswordForm.get('password');
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public httpProgressBarService: HttpProgressBarService
    ) { }

  reset() {
    const data = {
      password: this.password.value,
      token: this.route.snapshot.params.token
    };
    this.authService
      .resetPassword(data)
      .pipe(first())
      .subscribe(() => {
        this.resetPasswordSuccess = 'Password changed successfully.';
        this.resetPasswordError = '';
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 1000);
      }, (error) => {
        this.resetPasswordError = error.error.message;
        this.resetPasswordSuccess = '';
      });
  }
}
