import { Directive, Input, ViewContainerRef, TemplateRef, OnInit } from '@angular/core';
import { UserService } from '../../core/services/user.service';
import { Customer } from '../../core/models/Customer';
import { CustomerService } from 'src/app/customers/customers.service';

// tslint:disable-next-line: directive-selector
@Directive({selector: '[restrictAccess]'})

export class RestrictAccessDirective implements OnInit {
  private roles: string[] = [];
  private packages: string[] = [];
  private auxilliaryPackages: string[] = [];
  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private userService: UserService,
    private customerService: CustomerService
  ) { }

  @Input()
  set restrictAccess(roles: string[]) {
    this.roles = Array.isArray(roles) ? roles : [roles];
    this.roles = this.roles.map((role) => role.toUpperCase());
  }

  @Input()
  set restrictAccessPackages(packages: string[]) {
    this.packages = Array.isArray(packages) ? packages : [packages];
    this.packages = this.packages.map((p) => p.toUpperCase());
  }

  @Input()
  set restrictAccessAuxilliaryPackages(auxilliaryPackages: string[]) {
    this.auxilliaryPackages = Array.isArray(auxilliaryPackages) ? auxilliaryPackages : [auxilliaryPackages];
    this.auxilliaryPackages = this.auxilliaryPackages.map(p => p.toUpperCase());
  }

  private handleResellerCase(userRoleName: string): boolean {
    if (userRoleName === 'RESELLER') {
      const customerOwner: Customer | null = JSON.parse(sessionStorage.getItem('fullCustomerObject'));
      return customerOwner !== null;
    }
    return false;
  }

  ngOnInit() {
    const user = this.userService.currentUserValue;
    const userRoleName = user.role.name.toUpperCase();

    // check for auxilliary packages
    const subscribedPackages = this.userService.getAuxilliaryPackages();
    let productSubscriptions = subscribedPackages.map(subscription => subscription.package?.name.toUpperCase());
    const productSubscriptionsSet = new Set(productSubscriptions);
    productSubscriptions = [...productSubscriptionsSet];
    let subscribedToProduct = false;
    if (this.auxilliaryPackages.length !== 0) {
      const mergedArray = (productSubscriptions.concat(this.auxilliaryPackages));
      const auxilliaryPackagesMap = new Map();
      mergedArray.forEach((entry, index) => {
        // if this product has been subscribed to, add it to view
        if (auxilliaryPackagesMap.has(entry)) {
          subscribedToProduct = true;
        }
        auxilliaryPackagesMap.set(entry, index);
      });
      if (!subscribedToProduct) {
        this.viewContainer.clear();
        return;
      }
    }

    if (this.userService.isCurrentUserSuperAdmin && this.roles.includes(userRoleName)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    if (!this.roles.includes(userRoleName) && !this.handleResellerCase(userRoleName)) {
      this.viewContainer.clear();
      return;
    }

    const customerPackage = user.customer && user.customer.package.name.toUpperCase();

    if (this.packages.length === 0 || this.packages.includes(customerPackage)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }
    this.viewContainer.clear();
  }
}
